import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  ProgressBar,
  Alert,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import land from "./../../assets/land.jpg";
import smallLand from "./../../assets/land2.jpg";
import "./Enrollment.css";

const Start = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [states, setStates] = useState([]);
  const [lgas, setLGAs] = useState([]);
  const [wards, setWards] = useState([]);
  const [businessLGAs, setBusinessLGAs] = useState([]);
  const [businessWards, setBusinessWards] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [error, setError] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    verificationCode: "",
    firstName: "",
    middleName: "",
    lastName: "",
    confirmEmail: "",
    confirmPhone: "",
    phoneNumber: "",
    ninNumber: "",
    address: "",
    state: "",
    localGovernment: "",
    word: "",
    business_name: "",
    business_type: "",
    business_address: "",
    business_state: "",
    business_localGovernment: "",
    business_ward: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendVerificationCode = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post(
        `${apiUrl}/enrollments/send-verification-code`,
        { email: formData.email }
      );

      if (response.data.error) {
        Swal.fire({
          title: "Error",
          text: response.data.error || "Error sending verification code!",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        const code = response.data.code;

        localStorage.setItem("email", formData.email);
        localStorage.setItem("verificationCode", code);

        setStep(2);
      }
    } catch (error) {
      setErrorMessage("Failed to send verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const verifyCode = async () => {
    setLoading(true);
    setErrorMessage("");

    const storedEmail = localStorage.getItem("email");
    const storedCode = localStorage.getItem("verificationCode");

    if (
      formData.email === storedEmail &&
      formData.verificationCode === storedCode
    ) {
      setStep(3);
    } else {
      setErrorMessage("Invalid verification code or email. Please try again.");
    }

    setLoading(false);
  };

  const nextStep = async () => {
    if (!validateStep()) return;

    if (step === 1) {
      await sendVerificationCode();
    } else if (step === 2) {
      await verifyCode();
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleStateChange = async (e) => {
    const stateId = e.target.value;
    setFormData({ ...formData, state: stateId, localGovernment: "", word: "" });
    setWards([]);

    try {
      const response = await axios.get(`${apiUrl}/lgas/${stateId}`);
      setLGAs(response.data);
    } catch (error) {
      console.error("Error fetching LGAs:", error);
    }
  };

  const handleLGAChange = async (e) => {
    const lgaId = e.target.value;
    setFormData({ ...formData, localGovernment: lgaId, ward: "" });

    try {
      const response = await axios.get(`${apiUrl}/wards/${lgaId}`);
      setWards(response.data);
    } catch (error) {
      console.error("Error fetching wards:", error);
    }
  };

  const handleBusinessStateChange = async (e) => {
    const stateId = e.target.value;
    setFormData({
      ...formData,
      business_state: stateId,
      business_localGovernment: "",
      business_ward: "",
    });

    try {
      const response = await axios.get(`${apiUrl}/lgas/${stateId}`);
      setBusinessLGAs(response.data);
    } catch (error) {
      console.error("Error fetching business LGAs:", error);
    }
  };

  const handleBusinessLGAChange = async (e) => {
    const lgaId = e.target.value;
    setFormData({
      ...formData,
      business_localGovernment: lgaId,
      business_ward: "",
    });

    try {
      const response = await axios.get(`${apiUrl}/wards/${lgaId}`);
      setBusinessWards(response.data);
    } catch (error) {
      console.error("Error fetching business wards:", error);
    }
  };

  useEffect(() => {
    localStorage.removeItem("email");
    localStorage.removeItem("verificationCode");
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${apiUrl}/states`);
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log(formData);

      const response = await axios.post(
        `${apiUrl}/enrollments/apply`,
        formData
      );

      Swal.fire({
        title: "Success",
        text: response.data.message || "Enrollment was successful!",
        icon: "success",
        confirmButtonText: "OK",
      });

      setFormData({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        ninNumber: "",
        address: "",
        state: "",
        localGovernment: "",
        ward: "",
        business_name: "",
        business_type: "",
        business_address: "",
        business_state: "",
        business_localGovernment: "",
        business_ward: "",
        email: localStorage.getItem("email"),
      });

      localStorage.removeItem("email");
      localStorage.removeItem("verificationCode");

      window.location.reload();
    } catch (err) {
      setError("Failed to submit the enrollment form.");
      Swal.fire({
        title: "Error",
        text: err.response.data.message || "Enrollment was not successful!",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateStep = () => {
    let validationErrors = {};

    if (step === 1 && !formData.email) {
      validationErrors.email = "Email is required.";
    }

    if (step === 2 && !formData.verificationCode) {
      validationErrors.verificationCode = "Verification code is required.";
    }

    if (step === 3) {
      if (!formData.firstName) {
        validationErrors.firstName = "First name is required.";
      }
      if (!formData.lastName) {
        validationErrors.lastName = "Last name is required.";
      }
      if (!formData.confirmEmail) {
        validationErrors.confirmEmail = "Confirm email is required.";
      }
      if (!formData.confirmPhone) {
        validationErrors.confirmPhone = "Confirm phone is required.";
      }
      if (!formData.phoneNumber || formData.phoneNumber.length !== 11) {
        validationErrors.phoneNumber = "Phone number must be 11 digits.";
      }
      if (!formData.ninNumber || formData.ninNumber.length !== 11) {
        validationErrors.ninNumber = "NIN number must be 11 digits.";
      }
      if (!formData.address) {
        validationErrors.address = "Address is required.";
      }
      if (!formData.state) {
        validationErrors.state = "State is required.";
      }
      if (!formData.localGovernment) {
        validationErrors.localGovernment = "Local government is required.";
      }
      if (!formData.ward) {
        validationErrors.ward = "Ward is required.";
      }
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleCloseModal = () => setShowModal(false);
  const handleCheckStatus = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/enrollments/status`, {
        email,
      });
      Swal.fire({
        text: response.data.message,
        confirmButtonText: "OK",
      });
      handleCloseModal();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error || "Could not check your status , please try again later",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="enrollment-container">
      <div className="image-column">
        <img
          src={land}
          alt="Enrollment"
          className="full-image rounded large-screen-image"
        />
        <img
          src={smallLand}
          alt="Enrollment small"
          className="full-image rounded small-screen-image"
        />
      </div>

      <div className="form-box ">
        <h4 className="text-center mb-4 fs-2">Application Form</h4>

        <ProgressBar
          variant="success"
          now={(step / 3) * 100}
          label={`Step ${step} of 3`}
          className=" mb-4"
          style={{ width: "150px", height: "25px" }}
        />

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        {/* Form starts here and wraps all steps */}
        <Form onSubmit={handleSubmit} style={{ width: "80%" }}>
          {/* Step 1 */}
          {step === 1 && (
            <div className="shadow p-4 bg-light mb-4">
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="success"
                type="button"
                onClick={nextStep}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Verification Code"}
              </Button>
            </div>
          )}

          {/* Step 2 */}
          {step === 2 && (
            <div className="shadow p-4 bg-light">
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="verificationCode"
                      value={formData.verificationCode}
                      onChange={handleChange}
                      isInvalid={!!errors.verificationCode}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.verificationCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="secondary"
                type="button"
                onClick={prevStep}
                disabled={loading}
                className="ms-2 m-2"
              >
                Resend
              </Button>

              <Button
                variant="success"
                type="button"
                onClick={nextStep}
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify Code"}
              </Button>
            </div>
          )}

          {/* Step 3 */}
          {step === 3 && (
            <div
              className="shadow p-4 bg-light"
              style={{ height: "450px", width: "570px", overflow: "auto" }}
            >
              <h5>Biodata Information</h5>
              <Row className="mb-4">
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.phoneNumber}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>NIN Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="ninNumber"
                      value={formData.ninNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.ninNumber}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ninNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      as="select"
                      name="state"
                      value={formData.state}
                      onChange={handleStateChange}
                      isInvalid={!!errors.state}
                      required
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>LGA</Form.Label>
                    <Form.Control
                      as="select"
                      name="localGovernment"
                      value={formData.localGovernment}
                      onChange={handleLGAChange}
                      isInvalid={!!errors.localGovernment}
                      required
                    >
                      <option value="">Select LGA</option>
                      {lgas.map((lga) => (
                        <option key={lga.id} value={lga.id}>
                          {lga.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.localGovernment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group>
                    <Form.Label>Ward</Form.Label>
                    <Form.Control
                      as="select"
                      name="ward"
                      value={formData.ward}
                      onChange={handleChange}
                      isInvalid={!!errors.ward}
                      required
                    >
                      <option value="">Select Ward</option>
                      {wards.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                          {ward.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.ward}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <hr />
              <br />
              <h5>Business Information</h5>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_name"
                      value={formData.business_name}
                      onChange={handleChange}
                      isInvalid={!!errors.business_name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.business_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group>
                    <Form.Label>Business Type</Form.Label>
                    <Form.Control
                      type="text"
                      name="business_type"
                      value={formData.business_type}
                      onChange={handleChange}
                      isInvalid={!!errors.business_type}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.business_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Business Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="business_address"
                      value={formData.business_address}
                      onChange={handleChange}
                      isInvalid={!!errors.business_address}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.business_address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group>
                    <Form.Label>Business State</Form.Label>
                    <Form.Control
                      as="select"
                      name="business_state"
                      value={formData.business_state}
                      onChange={handleBusinessStateChange}
                      isInvalid={!!errors.business_state}
                      required
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.business_state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Business LGA</Form.Label>
                    <Form.Control
                      as="select"
                      name="business_localGovernment"
                      value={formData.business_localGovernment}
                      onChange={handleBusinessLGAChange}
                      isInvalid={!!errors.business_localGovernment}
                      required
                    >
                      <option value="">Select LGA</option>
                      {businessLGAs.map((lga) => (
                        <option key={lga.id} value={lga.id}>
                          {lga.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.business_localGovernment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group>
                    <Form.Label>Business Ward</Form.Label>
                    <Form.Control
                      as="select"
                      name="business_ward"
                      value={formData.business_ward}
                      onChange={handleChange}
                      isInvalid={!!errors.business_ward}
                      required
                    >
                      <option value="">Select Ward</option>
                      {businessWards.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                          {ward.title}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.business_ward}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <a href="/"
                variant="secondary"
                type="button"
                onClick={prevStep - 1}
                disabled={loading}
                className="btn btn-submit ms-2 m-2"
              >
                Back
              </a>

              <Button
                variant="success"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          )}
        </Form>

        <div className="status mt-3">
          Already Applied?{" "}
          <a
            href="#"
            onClick={() => setShowModal(true)}
            style={{ color: "green", textDecoration: "underline" }}
          >
            Check Application Status
          </a>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Check Application Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@example.com"
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleCheckStatus}>
              {loading ? "Checking Status..." : "Check"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Start;
