import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardNavbar from '../../components/DashboardNavbar';
import Sidebar from '../../components/Sidebar';
import Card from '../../components/Card';
import { faUsers, faChalkboardTeacher, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Dashboard = () => {
  // State to hold dashboard summary data
  const [summary, setSummary] = useState({
    totalEnrollments: 0,
    totalApproved: 0,
    totalDeclined: 0,
  });

  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);     // Error state

  const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect to fetch dashboard summary when the component mounts
  useEffect(() => {
    const fetchDashboardSummary = async () => {
      const token = localStorage.getItem('token'); // Assuming the token is stored in local storage

      try {
        const response = await axios.get(`${apiUrl}/dashboardSummary`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Attach token for authentication
          },
        });

        console.log(response.data);

        // Update the state with the fetched summary data
        setSummary({
          totalEnrollments: response.data.total,
          totalApproved: response.data.approved,
          totalDeclined: response.data.denied,
          totalPendding: response.data.pendding,
        });
      } catch (err) {
        setError('Failed to fetch dashboard summary');
      } finally {
        setLoading(false); // Stop loading once the request is completed
      }
    };

    fetchDashboardSummary();
  }, []);

  return (
    <div>
      <DashboardNavbar /> {/* Top navigation bar */}
      <div className="d-flex">
        <Sidebar /> {/* Sidebar */}

        <div className="main-content p-4" style={{ width: '100%' }}>
          <h1 className="mb-4">Dashboard</h1>

          {loading ? (
            <p>Loading summary data...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="row">
              <div className="col-md-4">
                <Card
                  title="Total Enrollments"
                  value={summary.totalEnrollments}
                  icon={faUsers}
                />
              </div>
              <div className="col-md-4">
                <Card
                  title="Total Pendding"
                  value={summary.totalPendding}
                  icon={faChalkboardTeacher}
                />
              </div>
              <div className="col-md-4">
                <Card
                  title="Total Approved"
                  value={summary.totalApproved}
                  icon={faChalkboardTeacher}
                />
              </div>
              <div className="col-md-4 mt-2">
                <Card
                  title="Total Declined"
                  value={summary.totalDeclined}
                  icon={faChalkboardTeacher} 
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
