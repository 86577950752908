import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataTable from "../../components/DataTable";
import { UserContext } from "../../context/UserContext";
import DashboardNavbar from "../../components/DashboardNavbar";
import Sidebar from "../../components/Sidebar";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const Enrollments = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [states, setStates] = useState([]);
  const [lgas, setLGAs] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedLga, setSelectedLga] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [viewUserData, setViewUserData] = useState(null);
  const { token } = useContext(UserContext);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [formData, setFormData] = useState({
    state: "",
    localGovernment: "",
  });

  useEffect(() => {
    // Fetch states from the API
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${apiUrl}/states}`);
        setStates(response.data); // Set fetched states
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchStates();
    fetchData();
  }, [token, selectedState, selectedLga]);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${apiUrl}/states`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStates(response.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchLgas = async (stateId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/lgas/${stateId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLGAs(response.data);
    } catch (error) {
      console.error("Error fetching LGAs:", error);
    }
  };

  const fetchData = async () => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let url = `${apiUrl}/enrollments`;
      if (selectedState) {
        url += `?state=${selectedState}`;
      }
      if (selectedLga) {
        url += `&lga=${selectedLga}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const enrollments = response.data || [];
      setData(enrollments);
      console.log(enrollments)
      setColumns([
        {
          Header: "S/N",
          accessor: (row, i) => i + 1,
        },
        {
          Header: "First Name",
          accessor: "first_name",
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        
        {
          Header: "State",
          accessor: "state_title",
        },
        {
          Header: "LGA",
          accessor: "lga_title",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            switch (value) {
              case "0":
                return "Pending";
              case "1":
                return "Approved";
              case "2":
                return "Declined";
              default:
                return "Unknown";
            }
          },
        },
        {
          Header: "Action",
          accessor: "id",
          Cell: ({ row }) => {
            const { id, status } = row.original;
            return (
              <div>
                <button
                  className="btn btn-info btn-sm mr-4 p-2 m-1"
                  onClick={() => handleView(row.original)}>
                  <FontAwesomeIcon icon={faEye} /> 
                </button>
                {status !== "1" && (
                  <button
                    className="btn btn-success btn-sm mr-4 p-2 m-1"
                    onClick={() => handleApprove(id)}>
                    <FontAwesomeIcon icon={faCheck} /> 
                  </button>
                )}
                {status !== "2" && (
                  <button
                    className="btn btn-danger btn-sm p-2 m-1"
                    onClick={() => handleDecline(id)}>
                    <FontAwesomeIcon icon={faTimes} /> 
                  </button>
                )}
              </div>
            );
          },
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      Swal.close();
    }
  };

  const handleApprove = async (id) => {
    try {
      await axios.post(
        `${apiUrl}/enrollments/${id}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire("Approved!", "The enrollment has been approved.", "success");
      fetchData();
    } catch (error) {
      console.error("Error approving enrollment:", error);
      Swal.fire("Error!", "Failed to approve the enrollment.", "error");
    }
  };

  const handleDecline = (id) => {
    setSelectedId(id);
    setViewUserData(null);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setReason("");
  };

  const handleModalSubmit = async () => {
    if (reason.trim() === "") {
      Swal.fire("Error!", "Please enter a reason.", "error");
      return;
    }

    try {
      await axios.post(
        `${apiUrl}/enrollments/${selectedId}/decline`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire("Declined!", "The enrollment has been declined.", "success");
      fetchData();
      handleModalClose();
    } catch (error) {
      console.error("Error declining enrollment:", error);
      Swal.fire("Error!", "Failed to decline the enrollment.", "error");
    }
  };

  const handleView = (userData) => {
    setViewUserData(userData);
    console.log(viewUserData);
    setShowModal(true);
  };


   // Function to handle lga change
   const handleLgaChange = async (e) => {
    const lga_id = e.target.value;
   console.log(lga_id)
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let url = `${apiUrl}/getEnrollmentByLga/${lga_id}`;
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const enrollments = response.data || [];
      setData(enrollments);
      console.log(enrollments)
      setColumns([
        {
          Header: "S/N",
          accessor: (row, i) => i + 1,
        },
        {
          Header: "First Name",
          accessor: "first_name",
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
        },

        {
          Header: "Email",
          accessor: "email",
        },
        
        {
          Header: "State",
          accessor: "state_title",
        },
        {
          Header: "LGA",
          accessor: "lga_title",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            switch (value) {
              case "0":
                return "Pending";
              case "1":
                return "Approved";
              case "2":
                return "Declined";
              default:
                return "Unknown";
            }
          },
        },
        {
          Header: "Action",
          accessor: "id",
          Cell: ({ row }) => {
            const { id, status } = row.original;
            return (
              <div>
                <button
                  className="btn btn-info btn-sm mr-4 p-2 m-1"
                  onClick={() => handleView(row.original)}>
                  <FontAwesomeIcon icon={faEye} /> 
                </button>
                {status !== "1" && (
                  <button
                    className="btn btn-success btn-sm mr-4 p-2 m-1"
                    onClick={() => handleApprove(id)}>
                    <FontAwesomeIcon icon={faCheck} /> 
                  </button>
                )}
                {status !== "2" && (
                  <button
                    className="btn btn-danger btn-sm p-2 m-1"
                    onClick={() => handleDecline(id)}>
                    <FontAwesomeIcon icon={faTimes} /> 
                  </button>
                )}
              </div>
            );
          },
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      Swal.close();
    }
  
      
  };

  // Function to handle status change
  const handleStatusChange = async (e) => {
    const status = e.target.value;
    setSelectedStatus(status); // Update selected status

    if(status !== "Filter by Status"){

   
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let url = `${apiUrl}/getEnrollmentByStatus/${status}`;
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const enrollments = response.data || [];
      setData(enrollments);
      console.log(enrollments)
      setColumns([
        {
          Header: "S/N",
          accessor: (row, i) => i + 1,
        },
        {
          Header: "First Name",
          accessor: "first_name",
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
        },

        {
          Header: "Email",
          accessor: "email",
        },
        
        {
          Header: "State",
          accessor: "state_title",
        },
        {
          Header: "LGA",
          accessor: "lga_title",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            switch (value) {
              case "0":
                return "Pending";
              case "1":
                return "Approved";
              case "2":
                return "Declined";
              default:
                return "Unknown";
            }
          },
        },
        {
          Header: "Action",
          accessor: "id",
          Cell: ({ row }) => {
            const { id, status } = row.original;
            return (
              <div>
                <button
                  className="btn btn-info btn-sm mr-4 p-2 m-1"
                  onClick={() => handleView(row.original)}>
                  <FontAwesomeIcon icon={faEye} /> 
                </button>
                {status !== "1" && (
                  <button
                    className="btn btn-success btn-sm mr-4 p-2 m-1"
                    onClick={() => handleApprove(id)}>
                    <FontAwesomeIcon icon={faCheck} /> 
                  </button>
                )}
                {status !== "2" && (
                  <button
                    className="btn btn-danger btn-sm p-2 m-1"
                    onClick={() => handleDecline(id)}>
                    <FontAwesomeIcon icon={faTimes} /> 
                  </button>
                )}
              </div>
            );
          },
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      Swal.close();
    }
  }
      
  };


  // Fetch LGAs when a state is selected
  const handleStateChange = async (e) => {
    
    const stateId = e.target.value;

    Swal.fire({
      title: "Loading...",
      text: "Please wait while we fetch the data.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let url = `${apiUrl}/getEnrollmentByState/${stateId}`;
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const enrollments = response.data || [];
      setData(enrollments);
      console.log(enrollments)
      setColumns([
        {
          Header: "S/N",
          accessor: (row, i) => i + 1,
        },
        {
          Header: "First Name",
          accessor: "first_name",
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
        },
        
        {
          Header: "State",
          accessor: "state_title",
        },
        {
          Header: "LGA",
          accessor: "lga_title",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            switch (value) {
              case "0":
                return "Pending";
              case "1":
                return "Approved";
              case "2":
                return "Declined";
              default:
                return "Unknown";
            }
          },
        },
        {
          Header: "Action",
          accessor: "id",
          Cell: ({ row }) => {
            const { id, status } = row.original;
            return (
              <div>
                <button
                  className="btn btn-info btn-sm mr-4 p-2 m-1"
                  onClick={() => handleView(row.original)}>
                  <FontAwesomeIcon icon={faEye} /> 
                </button>
                {status !== "1" && (
                  <button
                    className="btn btn-success btn-sm mr-4 p-2 m-1"
                    onClick={() => handleApprove(id)}>
                    <FontAwesomeIcon icon={faCheck} /> 
                  </button>
                )}
                {status !== "2" && (
                  <button
                    className="btn btn-danger btn-sm p-2 m-1"
                    onClick={() => handleDecline(id)}>
                    <FontAwesomeIcon icon={faTimes} /> 
                  </button>
                )}
              </div>
            );
          },
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      Swal.close();
    }

    setFormData({ ...formData, state: stateId, localGovernment: ""});
    
    try {
      const response = await axios.get(`${apiUrl}/lgas/${stateId}`);
      setLGAs(response.data); // Set fetched LGAs
    } catch (error) {
      console.error("Error fetching LGAs:", error);
    }
  };

  return (
    <div>
      <DashboardNavbar />
      <div className="d-flex">
        <Sidebar />
        <div className="main-content p-4" style={{ width: "85%" }}>
          <h4 className="mb-4">Enrollments</h4>
          <div className="form-inline" style={{ display:'flex'}}>
            <div className="col-3 m-3">
                <select className="form-control" value={selectedStatus} onChange={handleStatusChange}>
                  <option value="">Filter by Status</option>
                  <option value="0">Pending</option>
                  <option value="2">Declined</option>
                  <option value="1">Approved</option>
              </select>
            </div>


            <div className="col-4 m-3">
                <select className="form-control" name="state"  onChange={handleStateChange}>
                <option>Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.title}
                    </option>
                  ))}
              </select>
            </div>


            <div className="col-4 m-3">
                <select className="form-control" name="lga_id" onChange={handleLgaChange}>
                  <option value="">Select LGA</option>
                  {lgas.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.title}
                    </option>
                  ))}
              </select>
            </div>
            
          </div>

          <DataTable data={data} columns={columns} />
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {viewUserData ? "Enrollment Details" : "Decline Enrollment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewUserData ? (
            <div>
              <p>
                <strong>First Name:</strong> {viewUserData.first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {viewUserData.last_name}
              </p>
               <p>
                <strong>Email:</strong> {viewUserData.email}
              </p>
              <p>
                <strong>NIN:</strong> {viewUserData.ninNumber}
              </p>
              <p>
                <strong>Phone Number:</strong> {viewUserData.phone_number}
              </p>
              <p>
                <strong>State:</strong> {viewUserData.state_title}
              </p>
              <p>
                <strong>LGA:</strong> {viewUserData.lga_title}
              </p>
              <p>
                <strong>Address:</strong> {viewUserData.address}
              </p>
              <p>
                <strong>Business Address:</strong> {viewUserData.business_address}
              </p>
              <p>
                <strong>Business Name:</strong> {viewUserData.business_name}
              </p>
              <p>
                <strong>Business Type:</strong> {viewUserData.business_type}
              </p>
             
              <p>
                <strong>Status: </strong>{viewUserData.status === '0' ? 'Pending Approval' : viewUserData.status === '1' ? 'Approved' : 'Declined'}
              </p>
            </div>
          ) : (
            <div>
              <textarea
                className="form-control"
                placeholder="Enter reason for decline"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          {viewUserData ? null : (
            <Button variant="primary" onClick={handleModalSubmit}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Enrollments;
